import { Seo } from "~/components";
import { SignInPage } from "~/page-components";
import React from "react";

export default function SignIn() {
  return (
    <>
      <Seo />
      <SignInPage />
    </>
  );
}
